import * as React from "react"
import { Link } from "gatsby"
import {Layout} from "../components/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../global.css";
import "../components/buttons/buttons.css"
// styles
const pageStyles = {
    fontFamily: "var(--font-body)",
    lineHeight: 1.125,
    textAlign: "center",
    color: "#232129",
    padding: "120px 50px",
    fontSize: "1.25rem",
    minHeight: "50vh"
    /*fontFamily: "-apple-system, Roboto, sans-serif, serif",*/
}
const headingStyles = {
    color: "var(--secondary)",
    fontWeight: 600,
    margin: "auto",
    marginTop: 0,
    marginBottom: 34,
    maxWidth: 380,
    fontSize: "145%",
}

const paragraphStyles = {
    textAlign: "center",
    margin: "auto",
    marginBottom: 28,
    fontWeight: 300,
    fontSize: "18px"
}
const codeStyles = {
    color: "#000",
    padding: 4,
    fontSize: "275%",
    borderRadius: 4,
    fontWeight: 400,
    marginBottom: 34,
}

const btnStyle = {
    border: "none",
    borderRadius: "5px",
    padding: "7px 20px",
    margin: "5px",
    fontSize: "14px"
}

// markup
const NotFoundPage = () => {
    const pageTitle = 'La mejor plataforma de inversiones | Balanz';
    const pageDesc = 'No encontramos la página que estas buscando';
    const page = (
        <>
            <section style={pageStyles}>
            <p style={headingStyles}>No encontramos la página que estas buscando</p>
            <h1 style={codeStyles}>Error 404</h1>
            <p style={paragraphStyles}>
                Te dejamos algunos enlaces que podrían servirte</p>
            <Link to="/"><button className="btn-secondary" style={btnStyle}>Home</button></Link>
            <Link to="/inversiones"><button className="btn-secondary"  style={btnStyle}>Inversiones</button></Link>
            <Link to="/preguntas-frecuentes"><button className="btn-secondary"  style={btnStyle}>Preguntas frecuentes</button></Link>
            </section>
        </>
    )
  return (

    <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"404"}></Layout>
    </main>
  )
}

export default NotFoundPage
